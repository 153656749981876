import React, { useState } from "react";
import { useField } from "shared/lib/form";

import {
  DistanceInput,
  FtInput,
  Input,
  TextAreaInput,
  PhoneInput,
  PriceInput,
  NumberInput,
  TaxInput,
} from "../atoms/input";

type FieldProps<T> = Omit<T, "value" | "onChange"> & { name: string };

export function createFormField<I, O, P>(
  Component: React.FunctionComponent<P>,
  params?: {
    parse?(value: I): O;
    format?(value: O): I;
    onBlurHandler?: (value: O) => void;
  }
): React.FunctionComponent<FieldProps<P>> {
  return function FieldComponent(props: FieldProps<P>): JSX.Element {
    const { input, ...field } = useField({ name: props.name });

    const value = input.value;

    return (
      // @ts-ignore
      <Component
        {...props}
        onChange={(value: O) => {
          if (params?.format) {
            input.onChange(params.format(value));
          } else {
            input.onChange(value);
          }
        }}
        onBlur={(value: O) => {
          if (params?.onBlurHandler) {
            input.onChange(params.onBlurHandler(value));
          }
        }}
        value={params?.parse?.(value) ?? value ?? ""}
        error={field.isShowError ? field.error : ""}
      />
    );
  };
}

export const InputField = createFormField(Input);

export const LettersField = createFormField(Input, {
  format: (value: string) => value.replaceAll(/[^a-zA-Z\s]/g, ""),
});

export const NumberField = createFormField(Input, {
  format: formatIntFormValue,
});

export const TextAreaField = createFormField(TextAreaInput);

export const PhoneField = createFormField(PhoneInput);

export const FtInputField = createFormField(FtInput, {
  format: formatFloatFormValue,
  onBlurHandler: floatOnBlurFormatter,
});

export const NumberInputField = createFormField(NumberInput, {
  format: formatIntFormValue,
});

export const FloatInputField = createFormField(NumberInput, {
  format: formatFloatFormValue,
  onBlurHandler: floatOnBlurFormatter,
});

export const PriceField = createFormField(PriceInput, {
  format: formatFloatFormValue,
  onBlurHandler: (value) => floatOnBlurFormatter(value),
});

export const TaxField = createFormField(TaxInput, {
  format: formatFloatFormValue,
  onBlurHandler: floatOnBlurFormatter,
});

export const DistanceField = createFormField(DistanceInput, {
  format: formatFloatFormValue,
  onBlurHandler: floatOnBlurFormatter,
});

function formatFloatFormValue(value: string): string | null {
  return !Number.isNaN(value) ? value : null;
}

function floatOnBlurFormatter(value: string) {
  console.log(value);
  const formattedValue = value
    .replaceAll(",", ".")
    .replaceAll(/[^\d.-]|/g, "")
    .replace(/(^00)/, "0.0")
    .replace(/^\./, "0.")
    .replace(/\.(.*)\./, ".$1");
  return parseFloat(formattedValue);
}

function formatIntFormValue(value: string): number | null {
  const formattingValue = parseInt(value.replaceAll(/\D/g, ""));

  return !Number.isNaN(formattingValue) ? formattingValue : null;
}
