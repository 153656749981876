import {
  IconButton,
  InputAdornment,
  TextField as MaterialInput,
  InputLabel as MaterialInputLabel,
} from "@mui/material";
import styled, { css } from "styled-components";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MuiPhoneNumber from "material-ui-phone-number";

import { useOpenableState } from "shared/lib/use-openable-state";
import { CloseIcon as ClearIcon } from "shared/ui/icons";
import { forwardRef, RefObject } from "react";

export const basicInputStyles = css``;

type Field<T, C = T> = {
  value: T;
  onChange?: (value: C) => void;
  onFocus?: () => void;
  onBlur?: (value: C) => void;
};

export interface InputProps extends Field<string> {
  className?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  type?: string;
  error?: string | null;
  min?: number;
  max?: number;
  maxLength?: number;
  disabled?: boolean;
  readOnly?: boolean;
}

function InputComponent(
  props: InputProps,
  ref: RefObject<HTMLDivElement> | null | undefined
) {
  if (props.type === "password") {
    return <PasswordInput {...props} />;
  }

  return (
    <MaterialInput
      ref={ref}
      type={props.type ?? "text"}
      {...props}
      inputProps={{
        min: props.min,
        max: props.max,
        maxLength: props.maxLength,
      }}
      InputLabelProps={{
        shrink: Boolean(props.value?.toString()),
      }}
      {...getErrorProps(props.error)}
      onChange={
        props?.onChange
          ? (e) => {
              const value = e.target.value;
              if (props.maxLength) {
                if (value.length <= props.maxLength) {
                  props.onChange?.(value);
                }
              } else {
                props.onChange?.(value);
              }
            }
          : () => undefined
      }
    />
  );
}

function PasswordInput(props: Omit<InputProps, "type">) {
  const passwordOpenState = useOpenableState();

  return (
    <MaterialInput
      {...props}
      {...getErrorProps(props.error)}
      type={passwordOpenState.isOpened ? "text" : "password"}
      onChange={
        props.onChange ? (e) => props.onChange?.(e.target.value) : void 0
      }
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={passwordOpenState.toggle}
            >
              {passwordOpenState.isOpened ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export function getErrorProps(error?: string | null): {
  error: boolean;
  helperText?: string;
} {
  return {
    error: !!error,
    helperText: error?.trim(),
  };
}

export const Input = styled(forwardRef(InputComponent))``;

export function PhoneInputComponent(props: InputProps): JSX.Element {
  function handleChange(value: string) {
    const phoneValue = value.startsWith("+1") ? value : `+1${value}`;
    props.onChange?.(phoneValue);
  }

  return (
    <MuiPhoneNumber
      defaultCountry={"us"}
      onlyCountries={["us"]}
      disableDropdown
      countryCodeEditable={false}
      {...props}
      {...getErrorProps(props.error)}
      // @ts-ignore
      onChange={handleChange}
    />
  );
}

export const PhoneInput = styled(PhoneInputComponent)``;

function SearchInputComponent(props: InputProps): JSX.Element {
  return (
    <MaterialInput
      type="text"
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Clear onClick={() => props.onChange("")}>
              <Icon as={ClearIcon} />
            </Clear>
          </InputAdornment>
        ),
      }}
      {...getErrorProps(props.error)}
      onChange={props.onChange ? (e) => props.onChange(e.target.value) : void 0}
    />
  );
}
export const SearchInput = styled(SearchInputComponent)``;

const Clear = styled.div`
  cursor: pointer;
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
`;

interface TextAreaProps extends Omit<InputProps, "type"> {
  minRows?: number | string;
  maxRows?: number | string;
}

export function TextAreaInputComponent(props: TextAreaProps): JSX.Element {
  return (
    <MaterialInput
      multiline
      type="text"
      {...props}
      {...getErrorProps(props.error)}
      inputProps={{
        maxLength: props.maxLength,
      }}
      onChange={props.onChange ? (e) => props.onChange(e.target.value) : void 0}
    />
  );
}

export const TextAreaInput = styled(TextAreaInputComponent)``;

export function UnitInputComponent(
  props: InputProps & { unitLabel: string }
): JSX.Element {
  return (
    <MaterialInput
      {...props}
      {...getErrorProps(props.error)}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        min: props.min,
        max: props.max,
        maxLength: props.maxLength,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{props.unitLabel}</InputAdornment>
        ),
      }}
      onBlur={(e) => {
        props.onBlur?.(e.target.value);
      }}
      onChange={(e) => {
        const value = e.target.value;
        if (props.maxLength) {
          if (value.length <= props.maxLength) {
            props.onChange(value);
          }
        } else {
          props.onChange(value);
        }
      }}
    />
  );
}

export const NumberInput = styled(Input).attrs({
  type: "text",
})``;

const UnitInput = styled(UnitInputComponent)``;

export const FtInput = styled(UnitInput).attrs({
  unitLabel: "ft",
})``;

export const PriceInput = styled(UnitInput).attrs({
  type: "text",
  unitLabel: "$",
})``;

export const DistanceInput = styled(UnitInput).attrs({
  unitLabel: "mi",
})``;

export const TaxInput = styled(UnitInput).attrs({
  type: "text",
  unitLabel: "%",
})``;

export const InputLabel = styled(({ className, ...props }) => (
  <MaterialInputLabel {...props} classes={{ root: className }} />
))`
  font-size: 14px !important;
  line-height: 20px !important;
`;
